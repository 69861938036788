import { ChangeEvent } from "react";

import Button from "basics/button";

import {
  PartialPaymentMethodInfo,
  SetPaymentMethodInfo,
} from "../../duck/types";
import PaymentOptionsChooser from "../../../components/payment-options-chooser";

import modalClasses from "../../styles/classes.module.scss";

interface Props {
  nextStep: VoidFunction;
  setPaymentMethodInfo: SetPaymentMethodInfo;
  paymentMethodInfo: PartialPaymentMethodInfo;
}

const ChoosePaymentMethod = ({
  nextStep,
  setPaymentMethodInfo,
  paymentMethodInfo,
}: Props) => {
  const onChangePaymentMethods = (e: ChangeEvent<HTMLInputElement>) => {
    setPaymentMethodInfo(prevPaymentMethodInfo => ({
      ...prevPaymentMethodInfo,
      paymentOptions: prevPaymentMethodInfo.paymentOptions.map(
        paymentOption => {
          if (paymentOption.name === e.target.name) {
            return {
              ...paymentOption,
              isSelected: e.target.checked,
            };
          }

          return paymentOption;
        },
      ),
    }));
  };

  const setInitialPaymentOptions = paymentOptions => {
    setPaymentMethodInfo(prevPaymentMethodInfo => ({
      ...prevPaymentMethodInfo,
      paymentOptions,
    }));
  };

  return (
    <form
      className={modalClasses.form}
      onSubmit={event => {
        event.preventDefault();
        nextStep();
      }}
    >
      <h2 className={modalClasses.title}>
        Select your preferred payment methods
      </h2>
      <PaymentOptionsChooser
        regionId={
          paymentMethodInfo.nestedRegion?.id || paymentMethodInfo.region.id
        }
        paymentOptions={paymentMethodInfo.paymentOptions}
        setInitialOptions={setInitialPaymentOptions}
        onChange={onChangePaymentMethods}
      />
      <Button
        type="submit"
        isBlock
        themeName="primary"
        disabled={paymentMethodInfo.paymentOptions.every(
          ({ isSelected }) => !isSelected,
        )}
        className={modalClasses.nextButton}
      >
        Continue
      </Button>
    </form>
  );
};

export default ChoosePaymentMethod;
