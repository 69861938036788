import { LocalGroupedPaymentOption } from "./types";

export const getSelectedPaymentCategories = (
  paymentOptions: LocalGroupedPaymentOption[],
) => {
  const options: PaymentCategory[] = [];

  paymentOptions.forEach(({ isSelected, ...paymentOption }) => {
    if (isSelected) {
      options.push(paymentOption);
    }
  });

  return options;
};
