import { useEffect } from "react";
import joinClassNames from "classnames";

import { getDefault } from "selectors/common";

import { SearchSelect, serviceOptions } from "basics/select";
import Button from "basics/button";

import ErrorScreen from "components/error";

import { useConnect } from "./duck/hooks";
import {
  PartialPaymentMethodInfo,
  SetPaymentMethodInfo,
} from "../../duck/types";

import classes from "./styles/classes.module.scss";
import modalClasses from "../../styles/classes.module.scss";

interface Props {
  nextStep: VoidFunction;
  paymentMethodInfo: PartialPaymentMethodInfo;
  setPaymentMethodInfo: SetPaymentMethodInfo;
}

const ChooseCountry = ({
  nextStep,
  setPaymentMethodInfo,
  paymentMethodInfo,
}: Props) => {
  const { loadRegions, isLoaded, regions, regionsError } = useConnect();

  const setDefaultRegion = (
    regions: Record<Region["letterId"], Region>,
    isNestedRegion = false,
  ) => {
    setPaymentMethodInfo(prevPaymentMethodInfo => {
      if (prevPaymentMethodInfo.region) {
        return prevPaymentMethodInfo;
      }

      const defaultRegion = getDefault(regions);
      if (!defaultRegion) {
        return prevPaymentMethodInfo;
      }

      return {
        ...prevPaymentMethodInfo,
        [isNestedRegion ? "nestedRegion" : "region"]: defaultRegion,
      };
    });
  };

  useEffect(() => {
    loadRegions().unwrap().then(setDefaultRegion);
  }, []);

  const selectedRegion = paymentMethodInfo.region;
  const selectedNestedRegion = paymentMethodInfo.nestedRegion;

  const onChangeRegion = (currentRegion: Region, isNestedRegion = false) => {
    setPaymentMethodInfo(prevPaymentMethodInfo => ({
      ...prevPaymentMethodInfo,
      [isNestedRegion ? "nestedRegion" : "region"]: currentRegion,
      ...(prevPaymentMethodInfo.region?.id !== currentRegion?.id && {
        nestedRegion: isNestedRegion ? currentRegion : null,
        paymentOptions: [],
        currencies: [],
        selectedCurrency: null,
      }),
    }));
  };

  const onFilter = (query: string, { value }: LabelValue<Region>) => {
    const getIsIncludes = (value: string) =>
      value.toLowerCase().includes(query);

    return getIsIncludes(value.name) || getIsIncludes(value.alpha3Code);
  };

  const getRegionsSearchSelect = () => (
    <SearchSelect
      className={classes.searchSelect}
      placeholder="Search for country"
      value={selectedRegion}
      onChange={onChangeRegion}
      options={Object.values(regions)}
      renderButton={serviceOptions.Button}
      renderOption={serviceOptions.Option}
      onFilter={onFilter}
      isBlock
      isLoading={!isLoaded}
    />
  );

  const isNestedRegion = selectedRegion?.childExists;

  let content: JSX.Element;

  if (regionsError) {
    content = (
      <ErrorScreen className={classes.errorScreen}>
        <Button
          className={joinClassNames(
            modalClasses.nextButton,
            classes.tryAgainButton,
          )}
          themeName="primary"
          disabled={!isLoaded}
          onClick={() => {
            loadRegions();
          }}
        >
          Try again
        </Button>
      </ErrorScreen>
    );
  } else if (isNestedRegion) {
    content = (
      <>
        {getRegionsSearchSelect()}
        <SearchSelect
          className={classes.searchSelect}
          value={selectedNestedRegion}
          onChange={(nestedRegion: Region) =>
            onChangeRegion(nestedRegion, true)
          }
          options={
            Object.values(regions[selectedRegion?.letterId]?.states || {}) || []
          }
          renderButton={serviceOptions.Button}
          renderOption={serviceOptions.Option}
          onFilter={onFilter}
          isBlock
          isLoading={!isLoaded}
        />
      </>
    );
  } else {
    content = getRegionsSearchSelect();
  }

  return (
    <>
      <h2 className={modalClasses.title}>Select issuing country</h2>
      <p className={classes.description}>
        Issuing country denotes the country where your bank account was
        established
      </p>
      <div className={classes.contentWrapper}>{content}</div>
      {regionsError ? null : (
        <Button
          themeName="primary"
          disabled={
            !isLoaded ||
            !selectedRegion ||
            (selectedRegion?.childExists && !selectedNestedRegion)
          }
          onClick={() => {
            nextStep();
          }}
          isBlock
          className={modalClasses.nextButton}
        >
          Continue
        </Button>
      )}
    </>
  );
};

export default ChooseCountry;
